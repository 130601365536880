import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import WebDevelopment from "./components/courses/WebDevelopment";
import AppDevelopment from "./components/courses/AppDevelopment";
import CodingForCids from "./components/courses/CodingForkids";
import CyberSecurity from "./components/courses/CyberSecurity";
import DigitalMarketing from "./components/courses/DigitalMarketing";
import SpokenEnglish from "./components/courses/SpokenEnglish";
import EthicalHeacking from "./components/courses/EthicalHeacking";
// import GameDevelopment from "./components/courses/GameDevelopment";
import RsCit from "./components/courses/RsCit";
import TallyWithGst from "./components/courses/TallyWithGst";
import WebDesign from "./components/courses/WebDesign";
import Account from "./components/courses/Account";
import DigitalMarketingKotputli from "./components/services/DigitalMarketingKotputli";
import Cheatsheet from "./components/services/CheatSheet";
import Cyber from "./components/services/Cyber";
import WebSolution from "./components/services/WebSolution";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";
import Team from "./components/Team";
import Blog from "./components/blog/Blog";
import DigitalMarketingBlog from "./components/DigitalMarketingBlog";
import BlogDetail from "./components/blog/BlogDetail";
import Login from "./Login";
import { AuthProvider } from './context/AuthContext';
import AddBlog from "./components/blog/AddBlogForm";
import PopularCoursesCarousel from "./components/PopularCourses";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/appdevelopment" element={<AppDevelopment />} />
          <Route exact path="/codingforkids" element={<CodingForCids />} />
          <Route exact path="/cybersecurity" element={<CyberSecurity />} />
          <Route exact path="/digitalmarketing" element={<DigitalMarketing />} />
          <Route exact path="/spokenenglish" element={<SpokenEnglish />} />
          <Route exact path="/ethicalheacking" element={<EthicalHeacking />} />
          {/* <Route exact path="/gamedevelopment" element={<GameDevelopment />} /> */}
          <Route exact path="/rscit" element={<RsCit />} />
          <Route exact path="/tallywithgst" element={<TallyWithGst />} />
          <Route exact path="/webdesign" element={<WebDesign />} />
          <Route exact path="/webdevelopment" element={<WebDevelopment />} />
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/main" element={<Main />} />
          <Route exact path="/cyber" element={<Cyber />} />
          <Route exact path="/digitalmarketingkotputli" element={<DigitalMarketingKotputli />} />
          <Route exact path="/cheatsheet" element={<Cheatsheet />} />
          <Route exact path="/WebSolution" element={<WebSolution />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/blogs" element={<Blog />} />
          <Route exact path="/marketingblog" element={<DigitalMarketingBlog />} />
          <Route path="/blog/:id" element={<BlogDetail/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/addnewblog" element={<AddBlog/>} />
          <Route exact path="/popularcourses" element={<PopularCoursesCarousel/>} />


        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;